//COMPONENTS
import Base from "../container/base";
import Hero from "./hero";
import Form from "./form";
import ChatHero from "../panel/chatHero";
import Comparison from "../panel/compare";
import Features from "./features";
import Pricing from "./price";

export default function Home({ setOpen, articles = [] }) {
  return (
    <div className="h-full w-full bg-white">
      <Base setOpen={setOpen}>
        <Hero setOpen={setOpen} />
        {/* <Form /> */}
        <Features />
        <Pricing />
        {/* <Comparison /> */}
        {/* <ChatHero /> */}
        {/* <Demo />
        <Breakdown />
        <Start />
        <BlogPreview articles={articles} />
        <CTA /> */}
      </Base>
    </div>
  );
}
