import React, { useState, useEffect } from "react";
import FlipMove from "react-flip-move";
import { CheckIcon } from "@heroicons/react/20/solid";

export default function Carousel() {
  const initialItems = [
    { name: "Tom Belfort", company: "Stratton", date: "26/5/2023" },
    { name: "Zig Ziglar", company: "6sense", date: "27/5/2023" },
    { name: "Aaron Ross", company: "Drift", date: "28/5/2023" },
    { name: "Mary Ash", company: "Clari", date: "29/5/2023" },
  ];
  const [items, setItems] = useState(initialItems);
  const [counter, setCounter] = useState(2);

  useEffect(() => {
    const interval = setInterval(() => {
      setItems((prevItems) => {
        const newItems = [...prevItems];
        const lastItem = newItems.pop();
        return [lastItem, ...newItems];
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        return prevCounter >= 7 ? 2 : prevCounter + 1;
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="lg:h-[50vh] max-w-md mx-auto overflow-hidden">
      <FlipMove>
        {items.map((item, idx) => (
          <div
            key={item.name}
            className="mb-4 p-4 border border-gray-100 bg-white shadow-sm rounded-lg  grid grid-cols-5 justify-between"
          >
            <div className="col-span-3">
              <p className="text-xl font-header">
                {idx < 2
                  ? "Incoming lead"
                  : idx < 3
                  ? "Qualifying lead"
                  : "Scheduled Call"}
              </p>
              <p className="text-gray-400 text-sm mt-3 font-header">
                {idx < 2
                  ? `${item.name} from ${item.company}`
                  : idx < 3
                  ? `${counter} qualifying correspondence`
                  : `Booked for ${item.date}`}
              </p>
            </div>
            <div className="col-span-2">
              {idx < 2 ? (
                <p className="font-semibold text-center  bg-zinc-300 rounded-lg p-4  w-42">
                  Incoming -
                </p>
              ) : idx < 3 ? (
                <p className="font-semibold items-center justify-center bg-blue-500 text-white rounded-lg p-4 flex flex-row gap-2">
                  Qualifying{" "}
                  <svg
                    className="animate-spin h-5 w-5  self-center"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    enableBackground="new 0 0 40 40"
                    xml="preserve"
                  >
                    <path
                      opacity="0.2"
                      d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                    />
                    <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                  </svg>
                </p>
              ) : (
                <p className="font-semibold items-center justify-center bg-green-500 text-white rounded-lg p-4 flex flex-row gap-2">
                  Automated
                  <CheckIcon className="h-6 w-6" />
                </p>
              )}
            </div>
          </div>
        ))}
      </FlipMove>
    </div>
  );
}

// import { useEffect, useState } from "react";
// import { CheckIcon } from "@heroicons/react/20/solid";
// export default function Marquee() {
//   const [counter, setCounter] = useState(2);
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCounter((counter) => (counter + 1 > 6 ? 2 : counter + 1));
//     }, 2600);

//     return () => {
//       clearInterval(interval);
//     };
//   }, []);
//   console.log(counter);
//   return (
//     <div className="max-w-sm mx-auto flex flex-col  divide-y divide-dashed divide-zinc-400">
// <div className="pt-2">
// <div className="mb-4 p-4 border border-gray-100 bg-white shadow-sm rounded-lg  grid grid-cols-5 justify-between">
//   <div className="col-span-3">
//     <p className="text-xl font-header">Incoming lead</p>
//     <p className="text-gray-400 text-sm mt-3 font-header">
//       Ahmed from Tetra Sons
//     </p>
//   </div>
//   <div className="col-span-2">
//     <p className="font-semibold text-center  bg-zinc-300 rounded-lg p-4  w-42">
//       Incoming -
//     </p>{" "}
//   </div>
// </div>
//         <div className="mb-4 p-4 border border-gray-100 bg-white shadow-sm rounded-lg  grid grid-cols-5 justify-between">
//           <div className="col-span-3">
//             <p className="text-xl font-header">Incoming lead</p>
//             <p className="text-gray-400 text-sm mt-3 font-header">
//               Ahmed from Tetra Sons
//             </p>
//           </div>
//           <div className="col-span-2">
//             <p className="font-semibold text-center  bg-zinc-300 rounded-lg p-4  w-42">
//               Incoming -
//             </p>{" "}
//           </div>
//         </div>
//       </div>
//       <div className="pt-2">
//         <div className="mb-4 p-4 border border-gray-100 bg-white shadow-sm rounded-lg grid grid-cols-5  justify-between">
//           <div className="col-span-3">
//             <p className="text-xl font-header">Qualifying lead</p>
//             <p className="text-gray-400 text-sm mt-3 font-header">
//               7 qualifying correspondence
//             </p>
//           </div>
//           <div className="col-span-2">
// <p className="font-semibold bg-blue-500 text-white rounded-lg p-4 flex flex-row gap-2">
//   Qualifying{" "}
//   <svg
//     className="animate-spin h-5 w-5  self-center"
//     fill="currentColor"
//     xmlns="http://www.w3.org/2000/svg"
//     viewBox="0 0 40 40"
//     enableBackground="new 0 0 40 40"
//     xml="preserve"
//   >
//     <path
//       opacity="0.2"
//       d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
//     />
//     <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
//   </svg>
// </p>{" "}
//           </div>
//         </div>
//       </div>
//       <div className="pt-2">
//         <div className="mb-4 p-4 border border-gray-100 bg-white shadow-sm rounded-lg grid grid-cols-5 justify-between">
//           <div className="col-span-3">
//             <p className="text-xl font-header"> Scheduled Call</p>
//             <p className="text-gray-400 text-sm mt-3 font-header">29/5/2023</p>
//           </div>
//           <div className="col-span-2">
// <p className="font-semibold bg-green-500 text-white rounded-lg p-4 flex flex-row gap-2">
//   Automated
//   <CheckIcon className="h-6 w-6" />
// </p>{" "}
//           </div>
//         </div>
//       </div>
//     </div>
// <div className="relative w-full lg:col-span-3 flex justify-center overflow-y-hidden text-darkGray font-semibold">
//   <div className="relative w-full">
//     <div className="lg:py-2 animate-marquee whitespace-nowrap flex flex-col">
//       {OPTIONS.map((entry, index) => (
//         <span
//           key={index}
//           className={` my-2 bg-gray-50 p-4 w-full shadow-lg rounded-lg border ${
//             counter === index && "text-jackOrange"
//           }`}
//         >
//           {entry.name}
//         </span>
//       ))}
//     </div>
//     <div className="w-full absolute top-0 lg:py-2 animate-marquee2 whitespace-nowrap flex flex-col">
//       {OPTIONS.map((entry, index) => (
//         <span
//           key={index}
//           className={` my-2 bg-gray-50 p-4 w-full shadow-lg rounded-lg border ${
//             counter - 5 === index && "text-jackOrange"
//           }`}
//         >
//           {entry.name}
//         </span>
//       ))}
//     </div>
//     {/* <div className="h-24 w-full absolute top-0 left-0 bg-gradient-to-b from-black to-transparent">
//       {" "}
//     </div>
//     <div className="h-24 w-full absolute bottom-0 left-0 bg-gradient-to-t from-black to-transparent">
//       {" "}
//     </div> */}
//   </div>
// </div>
//   );
// }
