import { gql } from "@apollo/client";

export const SUBMIT_JACKALOPE = gql`
  mutation submitJackalopeRequest(
    $email: String!
    $name: ID!
    $employees: String!
  ) {
    submitJackalopeRequest(
      numberOfEmployees: $employees
      orgName: $name
      requestEmail: $email
    ) {
      success
    }
  }
`;

export const ASK = gql`
  mutation analyzeDoc($query: String!) {
    analyzeDoc(query: $query) {
      response
      error
    }
  }
`;

export const LOG_REFERRAL = gql`
  mutation logInboundReferral($IP: String!, $ref: String!) {
    logInboundReferral(affiliateReferenceId: $ref, prospectIdentifier: $IP) {
      success
      error
    }
  }
`;

export const EMAIL_CAPTURE = gql`
  mutation offerEmailCapture($email: String!) {
    offerEmailCapture(email: $email) {
      success
    }
  }
`;

export const VALIDATION = gql`
  mutation publicProjectValidation(
    $email: String!
    $endResultStatement: String!
    $problemStatement: String!
    $solutionStatement: String!
  ) {
    publicProjectValidation(
      email: $email
      endResultStatement: $endResultStatement
      problemStatement: $problemStatement
      solutionStatement: $solutionStatement
    ) {
      response
      error
    }
  }
`;

export const LEAD = gql`
  mutation consultingLeadWeb(
    $name: String!
    $email: String!
    $company: String!
  ) {
    companyLeadCapture(
      companyPurpose: $company
      fullName: $name
      email: $email
    ) {
      success
    }
  }
`;
