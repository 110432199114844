import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  CheckIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";

//HOOKS
import useOnboarding from "../../hooks/useOnboarding";

//COMPONENTS
import Input from "../inputs/whiteText";
import Loading from "../loading/page";

export default function CoverModal({ open, setOpen }) {
  const { isLoading, submitEmail } = useOnboarding();
  const [current, setCurrent] = useState(0);

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm();

  if (isLoading) return <Loading />;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[60]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-700 bg-opacity-90 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden w-full lg:w-5/6 h-5/6">
                <div className="w-full flex items-end justify-end mb-1">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="text-softWhite hover:text-gray-300"
                  >
                    <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                  </button>
                </div>
                <div className="  bg-black px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full h-full  rounded-xl sm:p-6">
                  <form
                    onSubmit={handleSubmit(submitEmail)}
                    className="max-w-4xl mx-auto flex flex-col items-center text-center justify-center h-[80vh] text-softWhite"
                  >
                    {current === 0 && (
                      <Name
                        setCurrent={setCurrent}
                        register={register}
                        trigger={trigger}
                        errors={errors}
                      />
                    )}
                    {current === 1 && (
                      <Email
                        setCurrent={setCurrent}
                        register={register}
                        trigger={trigger}
                        errors={errors}
                      />
                    )}
                    {current === 2 && (
                      <Company
                        setCurrent={setCurrent}
                        register={register}
                        errors={errors}
                      />
                    )}
                  </form>
                  <div className=" w-full  flex items-end justify-end text-softWhite">
                    <button
                      type="button"
                      onClick={() => setCurrent((current) => current - 1)}
                      disabled={current === 0}
                      className={`${
                        current === 0
                          ? "bg-gray-700"
                          : "bg-[#0042AC] hover:bg-blue-700"
                      } text-lg  px-2 py-2 rounded-l-md flex flex-row gap-2 items-center border-r border-gray-400`}
                    >
                      <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      disabled={current === 2}
                      onClick={() => setCurrent((current) => current + 1)}
                      className={`${
                        current === 2
                          ? "bg-gray-700"
                          : "bg-[#0042AC] hover:bg-blue-700"
                      } text-lg  px-2 py-2 rounded-r-md flex flex-row gap-2 items-center `}
                    >
                      <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const Name = ({ setCurrent, register, trigger, errors }) => {
  return (
    <div className="w-full">
      <h3 className="font-light text-3xl text-left w-full text-gray-50">
        1. What&apos;s your full name?
      </h3>
      <div className="p-6 w-full ">
        <Input
          title="Business Email"
          name="name"
          type="text"
          placeholder="Type your answer here..."
          register={register}
          errors={errors?.name}
        />
        <div className="w-full flex items-start justify-start mt-4 ">
          <button
            type="button"
            className="bg-[#0042AC] text-lg hover:bg-blue-700 px-4 py-2 rounded-md flex flex-row gap-2 items-center"
            onClick={async () => {
              const safe = await trigger(["name"]);
              if (safe) setCurrent(1);
            }}
          >
            OK
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

const Email = ({ setCurrent, register, trigger, errors }) => {
  return (
    <div className="w-full">
      <h3 className="font-light text-3xl text-left w-full text-gray-50">
        2. What&apos;s your email address?
      </h3>
      <div className="p-6 w-full ">
        <Input
          title="Business Email"
          name="email"
          type="email"
          placeholder="elon@spaceX.com"
          register={register}
          errors={errors?.email}
          valid={{
            maxLength: (v) =>
              v.length <= 50 || "The email should have at most 50 characters",
            matchPattern: (v) =>
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              "Email address must be a valid address",
          }}
        />
        <div className="w-full flex items-start justify-start mt-4 ">
          <button
            type="button"
            className="bg-[#0042AC] text-lg hover:bg-blue-700 px-4 py-2 rounded-md flex flex-row gap-2 items-center"
            onClick={async () => {
              const safe = await trigger(["email"]);
              if (safe) setCurrent(2);
            }}
          >
            OK
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};

const Company = ({ setCurrent, register, errors }) => {
  return (
    <div className="w-full">
      <h3 className="font-light text-3xl text-left w-full text-gray-50">
        3. What does your company do ?
      </h3>
      <p className="text-left w-full text-gray-500 px-6 pt-1">
        Any important info we should know on what you do? and company goals ?
      </p>
      <div className="p-6 w-full ">
        <Input
          title="Business Email"
          name="company"
          type="text"
          placeholder="type your answer here..."
          register={register}
          errors={errors?.company}
        />
        <div className="w-full flex items-start justify-start mt-4 ">
          <button
            type="submit"
            className="bg-[#0042AC] text-lg hover:bg-blue-700 px-4 py-2 rounded-md flex flex-row gap-2 items-center"
          >
            SUBMIT
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
};
