import { useEffect, useState } from "react";

const OPTIONS = [
  { name: "Cannabis" },
  { name: "Natural Health" },
  { name: "Confectionary" },
  { name: "Cosmetics" },
  { name: "Pharmaceutical" },
];
export default function Features() {
  const [counter, setCounter] = useState(2);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => (counter + 1 > 6 ? 2 : counter + 1));
    }, 2600);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <div className="bg-gray-200 rounded-lg"></div>
    </div>
  );
}
