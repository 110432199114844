import { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export default function Input({
  title,
  name,
  type,
  register,
  errors,
  placeholder,
  min,
  max,
  notReq,
  background = "bg-gray-50",
  disabled = false,
  valid,
}) {
  const [toggle, setToggle] = useState(null);
  return (
    <div className="w-full mx-auto">
      {errors && (
        <p className="text-red-500 text-start w-full ">{errors?.message}</p>
      )}
      <div
        className={`${
          errors ? "border-red-600 " : "border-gray-500 "
        } relative  border-b p-2  shadow-sm focus-within:border-indigo-600 focus:outline-none w-full `}
      >
        <input
          type={toggle || type}
          placeholder={placeholder}
          minlength={min}
          maxlength={max}
          disabled={disabled}
          className={` block w-full border-0 p-0 text-softWhite placeholder-gray-500 bg-black focus:ring-0  focus:outline-none sm:text-xl`}
          {...register(name, {
            required: notReq ? false : `Please enter a ${name}`,
            validate: valid || null,
          })}
        />
        {type === "password" && (
          <button
            type="button"
            onClick={() => setToggle((current) => (current ? null : "text"))}
            className="absolute top-2 right-6 text-gray-500 hover:text-gray-700"
          >
            {toggle ? (
              <EyeIcon className="h-5 w-5" />
            ) : (
              <EyeSlashIcon className="h-5 w-5" />
            )}
          </button>
        )}
      </div>
    </div>
  );
}
