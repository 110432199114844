import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import axios from "axios";

// REQUESTS
import {
  SUBMIT_JACKALOPE,
  LOG_REFERRAL,
  EMAIL_CAPTURE,
  VALIDATION,
  LEAD,
} from "../lib/requests";

const useDocuments = () => {
  const [openModal, setOpenModal] = useState(false);
  const [validation, setValidation] = useState({});
  const [response, setResponse] = useState(null);
  const router = useRouter();

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenModal(true);
    }, 40000); // 40 seconds

    return () => clearTimeout(timer);
  }, []);

  // REQUESTS
  /************************************************************************* */

  const [inquire, inquiryResponse] = useMutation(SUBMIT_JACKALOPE, {
    fetchPolicy: "network-only",
    onCompleted: (d) => router.push("/thankyou"),
    onError: (d) => alert(d),
  });

  const [logRef, refResponse] = useMutation(LOG_REFERRAL, {
    fetchPolicy: "network-only",
    onCompleted: (d) => console.log(d),
    onError: (d) => console.log(d),
  });

  const [logEmail, emailResponse] = useMutation(EMAIL_CAPTURE, {
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      if (openModal) setOpenModal(false);
      router.push("/thankyou");
    },
    onError: (d) => console.log(d),
  });

  const [validate, validationResponse] = useMutation(VALIDATION, {
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      setResponse(d.publicProjectValidation.response);
    },
    onError: (d) => console.log(d),
  });

  const [logPriceEmail, priceResponse] = useMutation(EMAIL_CAPTURE, {
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      if (openModal) setOpenModal(false);
      router.push("/thankyou");
    },
    onError: (d) => console.log(d),
  });

  const [capture, captureResponse] = useMutation(LEAD, {
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      router.push("/thankyou");
    },
    onError: (d) => console.log(d),
  });

  // FUNCTIONS
  /************************************************************************* */

  async function inquireJackalope(data) {
    inquire({
      variables: data,
    });
  }

  async function logReferral(data) {
    const IP = await axios.get("https://geolocation-db.com/json/");
    logRef({
      variables: {
        IP: IP.data?.IPv4,
        ref: data,
      },
    });
  }
  async function submitEmail(data) {
    capture({
      variables: data,
    });
  }

  async function priceEmail(data) {
    logEmail({
      variables: {
        email: data.email,
      },
    });
  }

  async function submitFeedback(data) {
    setValidation(data);
    validate({
      variables: {
        email: data.email,
        endResultStatement: data.result,
        problemStatement: data.problem,
        solutionStatement: data.solution,
      },
    });
  }

  return {
    isLoading:
      captureResponse.loading ||
      validationResponse.loading ||
      inquiryResponse.loading ||
      emailResponse.loading,
    openModal,
    setOpenModal,
    inquireJackalope,
    submitEmail,
    priceEmail,
    submitFeedback,
    validation,
    logReferral,
    response,
  };
};
export default useDocuments;
