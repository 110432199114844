import React from "react";
import Link from "next/link";
import { PopupButton } from "@typeform/embed-react";

export default function Hero({ setOpen }) {
  return (
    <div className="bg-black text-white py-20 lg:py-40">
      <div className="flex flex-col lg:grid grid-cols-5  gap-12 max-w-7xl mx-auto w-full px-6">
        <div className="col-span-2">
          <h1 className="mt-8 text-5xl lg:text-6xl font-header tracking-wide font-bold  text-left">
            Get answers for any process. Instantly!
          </h1>
          <p className="text-lg my-6 text-left  text-gray-400 max-w-sm lg:max-w-2xl mx-auto ">
            From operation processes to sale flows, Jackrabbit Ops brings your
            processes to life. You can ask questions, get summaries, find
            information, and more.
          </p>
          <div className="flex flex-col  lg:flex-row gap-2 lg:gap-4">
            <Link
              href="/pdf"
              className="w-max border border-gray-800 text-gray-900 rounded-md px-6 py-2 hover:bg-gray-900 hover:text-white bg-white"
            >
              Try the demo →
            </Link>
          </div>
        </div>
        <img src="/text.png" className="col-span-3 rounded-lg" />
      </div>
    </div>
  );
}
