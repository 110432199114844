import { Fragment } from "react";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
  CheckIcon,
  MinusIcon,
} from "@heroicons/react/20/solid";

const tiers = [
  {
    name: "Human",
    id: "tier-basic",
    href: "#",
    priceMonthly: "$9",
    description: "Quis suspendisse ut fermentum neque vivamus non tellus.",
    mostPopular: false,
  },
  {
    name: "Jackrabbit",
    id: "tier-essential",
    href: "#",
    priceMonthly: "$29",
    description: "Quis eleifend a tincidunt pellentesque. A tempor in sed.",
    mostPopular: true,
  },
];
const features = [
  {
    name: "Cost",
    tiers: {
      Human: "Minimum $1000/hire per month",
      Jackrabbit: "$349 for the entire team",
    },
  },
  {
    name: "Operation Hours",
    tiers: { Human: "Limited to 8 hours a day", Jackrabbit: "Operates 24/7" },
  },
  {
    name: "Speed of Response:",
    tiers: {
      Human: "Dependent on individual speed, can take hours or days",
      Jackrabbit: "Instant response, no waiting time for prospects",
    },
  },
  {
    name: "Efficiency",
    tiers: {
      Human: "Prone to errors or oversights, can miss leads",
      Jackrabbit: "Never misses a lead",
    },
  },
  {
    name: "Scalability",
    tiers: {
      Human: "More leads require more hires",
      Jackrabbit: "Handles any volume of leads",
    },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <div className="bg-white py-24 ">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            What&apos;s the difference between Humans and Jackrabbit Ops?
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          If you have a growing business, chances are you&apos;ll quickly
          outgrow the capacity of manual follow-ups. Jackrabbit Ops scales with
          your needs, providing a responsive system where every lead is
          instantly engaged, qualified, and managed, keeping your team&apos;s
          focus on closing deals, not chasing them.
        </p>

        {/* xs to lg */}
        <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
          {tiers.map((tier) => (
            <section
              key={tier.id}
              className={classNames(
                tier.mostPopular
                  ? "rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200"
                  : "",
                "p-8"
              )}
            >
              <h3 className="mt-2 flex items-baseline gap-x-1 text-gray-900">
                <span className="text-4xl font-bold"> {tier.name}</span>
              </h3>
              {tier.mostPopular && (
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className="border-gray-800 hover:text-gray-900 bg-gray-900 text-white hover:bg-white mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Apply - 2 mins
                </a>
              )}
              <ul
                role="list"
                className="mt-10 space-y-4 text-sm leading-6 text-gray-900"
              >
                {features.map((feature) =>
                  feature.tiers[tier.name] ? (
                    <li key={feature.name} className="flex gap-x-3">
                      {tier.mostPopular ? (
                        <HandThumbUpIcon
                          className="h-6 w-5 flex-none text-jackOrange"
                          aria-hidden="true"
                        />
                      ) : (
                        <HandThumbDownIcon
                          className="h-6 w-5 flex-none text-gray-800"
                          aria-hidden="true"
                        />
                      )}
                      <span>
                        {feature.name}{" "}
                        {typeof feature.tiers[tier.name] === "string" ? (
                          <span className="text-sm leading-6 text-gray-500">
                            ({feature.tiers[tier.name]})
                          </span>
                        ) : null}
                      </span>
                    </li>
                  ) : null
                )}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="isolate mt-20 hidden lg:block">
          <div className="relative -mx-8">
            {tiers.some((tier) => tier.mostPopular) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{
                    marginLeft: `${
                      (tiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                    }%`,
                  }}
                >
                  <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {tiers.map((tier) => (
                    <td key={tier.id} className="px-6 pt-2 xl:px-8">
                      <div className="flex h-full flex-col">
                        <div className="flex h-full w-full items-baseline gap-x-1 text-gray-900 pt-6">
                          <span className="text-4xl font-bold mx-auto">
                            {tier.name}
                          </span>
                        </div>
                        {tier.mostPopular ? (
                          <a
                            href={tier.href}
                            className="mt-8 w-full text-center border border-gray-800 hover:text-gray-900 rounded-md px-6 py-2 bg-gray-900 text-white hover:bg-white"
                          >
                            Apply - 2 mins
                          </a>
                        ) : (
                          <div className="mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-white">
                            |
                          </div>
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
                {features.map((feature) => (
                  <tr key={feature.name}>
                    <th
                      scope="row"
                      className="py-4 text-sm font-normal leading-6 text-gray-900"
                    >
                      {feature.name}
                      <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                    </th>
                    {tiers.map((tier) => (
                      <td key={tier.id} className="px-6 py-4 xl:px-8">
                        {typeof feature.tiers[tier.name] === "string" ? (
                          <div className="text-center text-sm leading-6 text-gray-500">
                            {feature.tiers[tier.name]}
                          </div>
                        ) : (
                          <>
                            {feature.tiers[tier.name] === true ? (
                              <CheckIcon
                                className="mx-auto h-5 w-5 text-indigo-600"
                                aria-hidden="true"
                              />
                            ) : (
                              <MinusIcon
                                className="mx-auto h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            )}

                            <span className="sr-only">
                              {feature.tiers[tier.name] === true
                                ? "Included"
                                : "Not included"}{" "}
                              in {tier.name}
                            </span>
                          </>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
