import image from "../public/logo.png";

// eslint-disable-next-line import/prefer-default-export
export const config = {
  siteName: "jackrabbitops",
  blogTitle: "Blog",
  baseUrl: "jackrabbitops.com",
  websiteLogo: image,
  twitterHandle: "@junaid.sudo",
  twitterCardType: "JackRabbit Blog",

  css: {
    primaryColor: "#111",
    accentColor: "#455A64",
    lightGray: "#eeeeee",
    backgroundColor: "#fff",
    black: "#111",
    white: "#fff",
    textColor: "#333",
    opacity: 0.7,
  },
};

const privacy = {
  ...config,
  siteDescription: "Privacy policy for AI project manager ",
  defaultPageTitle: "Privacy | JackRabbit Ops",
};

const FAQ = {
  ...config,
  siteDescription: "Frequently asked questions for Jackrabbit Ops",
  defaultPageTitle: "FAQ | JackRabbit Ops",
};

const landing = {
  ...config,
  siteDescription:
    "Respond instantly to all your leads, qualify them and book meetings all on autopilot. All at a fraction of the cost of an SDR. ",
  defaultPageTitle: "Never miss a new customer | Jackrabbit Ops ",
};

const demo = {
  ...config,
  siteDescription:
    "See Jackrabbit Ops in action, instantly responding to RFQs and qualifying the leads for you.",
  defaultPageTitle: "Instantly qualify leads | Jackrabbit Ops ",
};

const offer = {
  ...config,
  siteDescription:
    "Try for free. Upgrade to the low-cost paid plan of $16/user to unlock your team's success.",
  defaultPageTitle: "Pricing | JackRabbit Ops",
};

const thankYou = {
  ...config,
  siteDescription:
    "Our team will be in touch to get you onboarded for your AI project manager.",
  defaultPageTitle: "Get In Touch | JackRabbit Ops",
};

const blogHome = {
  ...config,
  siteDescription:
    "Explore project management best practices and latest news with Jackrabbit Ops.",
  defaultPageTitle: "Blog | JackRabbit Ops",
};

const features = {
  ...config,
  siteDescription:
    "Jackrabbit Ops AI manager recognizes roadblocks and highlights them to roles that can solve them.",
  defaultPageTitle: "AI Manager Features | JackRabbit Ops",
};

const challenge = {
  ...config,
  siteDescription: "Productive team or your money back",
  defaultPageTitle: "90 Day Challenge | JackRabbit Ops",
};

export const configSet = {
  FAQ,
  features,
  privacy,
  landing,
  blogHome,
  offer,
  challenge,
  thankYou,
  demo,
};
