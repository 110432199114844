import React from "react";
import Link from "next/link";
import { PopupButton } from "@typeform/embed-react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Icons from "./icons";
import Marquee from "./marquee";

export default function Hero({ setOpen }) {
  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
        <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4">
            <div className="mx-auto max-w-2xl">
              <div className="max-w-lg">
                <h1 className="mt-10 text-5xl font-header tracking-tight text-gray-900 sm:text-7xl">
                  Never miss a new customer.
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Jackrabbit&apos;s AI will respond to leads and book meetings -
                  just like your best sales person.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="https://sdr.jackrabbitops.com/register"
                    className="w-max border border-gray-800 hover:text-gray-900 rounded-md px-6 py-2 bg-gray-900 text-white hover:bg-white"
                  >
                    Get Access - 2mins
                  </a>
                  <Link
                    href="/demo"
                    className="font-medium w-max border-2 border-jackOrange hover:text-gray-900 rounded-md px-6 py-2 bg-orange-50 text-jackOrange hover:bg-gray-50"
                  >
                    Test AI
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <Icons /> */}
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
            <div
              className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-orange-600/10 ring-1 ring-orange-50 md:-mr-20 lg:-mr-36"
              aria-hidden="true"
            />
            <Marquee />
            {/* <div className="shadow-lg md:rounded-3xl">
              <div className="bg-jackOrange [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                <div
                  className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-orange-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                  aria-hidden="true"
                />
                <div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                  <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
                    <div className="w-screen overflow-hidden rounded-tl-xl bg-gray-900">
                      <img src="/screenCap.png" className=" lg:h-[50vh]" />
                    </div>
                  </div>
                  <div
                    className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
      <div className="bg-[#F6800A]">
        <div className="max-w-7xl mx-auto p-6 flex flex-row gap-4">
          <img src="/quote.png" className="h-5 w-5" alt="Quotation mark" />
          <p className="font-medium text-lg">
            “Saves countless hours... [Jackrabbit Ops] has greatly improved how
            we implement and standardize activities.”{" "}
            <span className="italic">
              - Devon Lio, Director of Operations at ATG Pharma Inc
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
