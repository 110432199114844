import { NextSeo } from "next-seo";
import { createSEOPageConfig } from "../utils/seo";
import { configSet } from "../lib/pageConfig";
import { getAllFilesFrontMatter } from "../utils/mdx";
import { useState } from "react";
// COMPONENTS
import Head from "../components/head";
import Landing from "../components/landing";
import FormModal from "../components/modal/form";

export async function getStaticProps() {
  const articles = (await getAllFilesFrontMatter("blog")).sort(function (a, b) {
    return new Date(b.publishDate) - new Date(a.publishDate);
  });

  return { props: { articles } };
}

export default function Home({ articles = [] }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="font-copy bg-black min-h-screen">
      <Head />
      <NextSeo {...createSEOPageConfig(configSet.landing)} />
      <FormModal open={open} setOpen={setOpen} />
      <Landing setOpen={setOpen} articles={articles} />
    </div>
  );
}
