import {
  GlobeAltIcon,
  ChatBubbleLeftRightIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import YouTube from "react-youtube";

const features = [
  {
    name: "Handles RFQs",
    description:
      "100% of the RFQs made for your business get personalized replies within an hour. ",
    icon: GlobeAltIcon,
  },
  {
    name: "Qualifies leads",
    description:
      "Evaluate all leads and identifies the customers with intent to buy.",
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: "Books Meetings",
    description: "Get prospects booking meetings to close more sales.",
    icon: UsersIcon,
  },
];

export default function Example() {
  return (
    <div id="features" className="bg-white pt-24 sm:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-4xl font-header tracking-tight text-gray-900 sm:text-6xl text-center">
          Close more sales!
          {/* <span className="text-jackOrange">24/7</span> SDR. */}
        </h2>
        <div className="mx-auto mt-20 max-w-2xl  lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="flex flex-col h-full justify-between p-4  py-6 rounded-xl  bg-white "
              >
                <dt className="font-semibold  flex flex-col items-center justify-center leading-7 text-zinc-900 text-2xl">
                  <div className="mb-6 flex h-20 w-20 items-center justify-center rounded-full bg-orange-50">
                    <feature.icon
                      className="h-12 w-12 text-jackOrange"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-5 flex flex-auto flex-col  items-center justify-center text-center  text-lg leading-7 text-zinc-600">
                  <p className="h-max">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="hidden lg:flex mx-auto mt-20 max-w-2xl  lg:max-w-none flex justify-center">
          <YouTube
            videoId="nAo96hXq0kQ"
            opts={{ height: "360", width: "670" }}
          />
        </div>
        <div className="lg:hidden mx-auto mt-20 max-w-2xl  lg:max-w-none flex justify-center">
          <YouTube
            videoId="nAo96hXq0kQ"
            opts={{ height: "320", width: "320" }}
          />
        </div>
      </div>
    </div>
  );
}
